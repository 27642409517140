<template>
  <v-container>
    <v-row>
      <v-col
        v-for="(item, i) in items"
        :key="i"
        cols="12"
      >
        <v-card
          :color="item.color"
        >
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title
                class="text-h5"
              >
                {{ item.title }}
              </v-card-title>
              <v-card-subtitle> {{ item.artist }}</v-card-subtitle>
              <v-card-actions>
                <v-btn
                  :color="item.cta && item.cta.color ? item.cta.color : 'primary'"
                  @click="$router.push('/statuts')"
                >
                  {{ item.cta }}
                  <v-icon v-if="item.icon">
                    {{ item.icon }}
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </div>
            <div>
              <v-img
                :src="item.src"
                contain
                max-width="200"
                width="100%"
              />
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default {
    name: 'SectionCards',

    data: () => ({
      items: [
        {
          // color: 'secondary',
          src: '/img/logo-fondIndePresse.133b7800.jpg',
          title: 'Statuts',
          artist: 'Accéder et lire les statuts du fonds pour la liberté de la presse',
          cta: 'Lire les statuts',
          // icon: 'mdi-play'
        },
      ],
    }),
  }
</script>
