import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,_vm._l((_vm.items),function(item,i){return _c(VCol,{key:i,attrs:{"cols":"12"}},[_c(VCard,{attrs:{"color":item.color}},[_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c(VCardSubtitle,[_vm._v(" "+_vm._s(item.artist))]),_c(VCardActions,[_c(VBtn,{attrs:{"color":item.cta && item.cta.color ? item.cta.color : 'primary'},on:{"click":function($event){return _vm.$router.push('/statuts')}}},[_vm._v(" "+_vm._s(item.cta)+" "),(item.icon)?_c(VIcon,[_vm._v(" "+_vm._s(item.icon)+" ")]):_vm._e()],1)],1)],1),_c('div',[_c(VImg,{attrs:{"src":item.src,"contain":"","max-width":"200","width":"100%"}})],1)])])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }